.fade {
    -webkit-transition: opacity .15s linear;
    transition: opacity .15s linear
}

.fade:not(.show) {
    opacity: 0
}

@media (min-width:576px) {
    .modal-dialog {
        margin: 0 auto;
        max-width: 655px
    }
}

.modal-dialog {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    max-width: 655px;
    position: relative;
    width: auto;
    height: 100vh;
    pointer-events: none
}

.modal-content {
    background: #fff;
    position: relative;
    display: -webkit-box;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    max-width: 655px;
    width: 100%;
    pointer-events: auto;
    border: none;
    outline: 0
}

.close:not(:disabled):not(.disabled) {
    cursor: pointer
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    background: rgba(10, 22, 30, 0.9);
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
    -webkit-transition: transform .3s ease-out;
    -moz-transition: transform .3s ease-out;
    -ms-transition: transform .3s ease-out;
    -o-transition: transform .3s ease-out
}

.modal.fade .modal-dialog {
    -webkit-transition: -webkit-transform .3s ease-out;
    transition: -webkit-transform .3s ease-out;
    transition: transform .3s ease-out;
    transition: transform .3s ease-out, -webkit-transform .3s ease-out;
    -webkit-transform: translate(0, -50px);
    transform: translate(0, -50px);
    -webkit-transition: transform .3s ease-out;
    -moz-transition: transform .3s ease-out;
    -ms-transition: transform .3s ease-out;
    -o-transition: transform .3s ease-out
}

.modal.show .modal-dialog {
    -webkit-transform: none;
    transform: none
}

.modal-close {
    position: absolute;
    width: 21px;
    height: 21px;
    background: url(../img/close.svg) no-repeat center;
    right: 31px;
    top: 31px;
    font-size: 25px;
    font-weight: 400;
    text-shadow: none;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    opacity: 1
}

.form-popup {
    padding: 45px 45px 60px 45px;
}

.modal-open {
    overflow: hidden;
    padding-right: 17px!important;
}

@media screen and (max-width: 767px) {
    .container {
        width: 320px;
    }
    .modal-content {
        margin: 0 15px;
    }
}